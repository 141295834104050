<template>
  <div class="mobile">
    <div class="index-bg">
      <div class="top">
        <div class="left">
          <p class="text">{{ username }}</p>
        </div>
        <!-- <div class="right">
					<div class="message" @click="goAnnouncement"><img width="36px" src="../../assets/img/ic_youxiaoxi.png" alt="智岚博丰"></div>
					<div class="avatar"><img width="44px" src="../../assets/img/ic_logo.png" alt="智岚博丰"></div>
				</div> -->
      </div>
      <div class="terms">
        <router-link to="/Distribution/list">
          <div class="item item1">
            <p class="name">总收益<van-icon name="arrow" /></p>
            <p class="number">{{ walletData.balance + walletData.freeze }}</p>
          </div>
        </router-link>
        <router-link to="/Distribution/list">
          <div class="item item2">
            <p class="name">可提现</p>
            <p class="number">{{ walletData.balance }}</p>
          </div>
        </router-link>
        <router-link to="/Distribution/list">
          <div class="item item3">
            <p class="name">冻结中</p>
            <p class="number">{{ walletData.freeze }}</p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="list">
      <p class="title">我的数字通证</p>
      <div class="operating-box">
        <div class="asset-item">
          <div class="left">
            <div class="my-icon">
              <img width="44px" src="../../assets/img/ic_shishijiage.png" alt="智岚博丰" />
            </div>
            <div class="text">
              <p class="text1">证明测试奖励</p>
            </div>
          </div>
          <div class="price right">
            <span style="font-weight: bold;margin-right: 10px;">{{
              info.t32
            }}</span>
          </div>
        </div>
      </div>
      <div class="operating-box" @click="goAssets()">
        <div class="asset-item">
          <div class="left">
            <div class="my-icon">
              <img width="44px" src="../../assets/img/ic_shengtaibankuai.png" alt="aleo" />
            </div>
            <div class="text">
              <p class="text1">ALEO</p>
            </div>
          </div>
          <div class="price right">
            <span style="font-weight: bold;">{{ aleo }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>

      <!-- <div class="operating-box" @click="goIoAssets()">
        <div class="asset-item">
          <div class="left">
            <div class="my-icon">
              <img width="44px" src="../../assets/img/ic_shengtaibankuai.png" alt="io" />
            </div>
            <div class="text">
              <p class="text1">IO</p>
            </div>
          </div>
          <div class="price right">
            <span style="font-weight: bold;">{{ io }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div> -->

      <p class="title title_special">PPS概况</p>
      <div class="account-box">
        <div class="account-item" @click="goPPS()">
          <div class="left">
            <div class="my-icon">
              <img width="44px" src="../../assets/img/ic_gongxianzhi.png" alt="智岚博丰" />
            </div>
            <div class="text">
              个人PPS
            </div>
          </div>
          <div class="right">
            {{ info.selfCount }}
          </div>
        </div>
        <div class="account-item" @click="goTeam()">
          <div class="left">
            <div class="my-icon">
              <img width="44px" src="../../assets/img/ic_jiangyishouyi.png" alt="智岚博丰" />
            </div>
            <div class="text">
              直接销售
            </div>
          </div>
          <div class="right">
            {{ info.teamCount }}
          </div>
        </div>
        <div class="account-item" v-if="this.$store.state.base.userId == 33">
          <div class="left">
            <div class="my-icon">
              <img width="44px" src="../../assets/img/ic_hehuoren.png" alt="智岚博丰" />
            </div>
            <div class="text">
              团队PPS
            </div>
          </div>
          <div class="right">
            {{ info.teamPps }}
          </div>
        </div>
      </div>
    </div>
    <van-dialog class="pact" v-model="powerShow" title="通知" :showConfirmButton="false">
      <div class="contract_content">
        <div v-if="willExpire > 0">
          您有{{ willExpire }}份电力即将到期，请提前购买，以免影响提现操作。
        </div>
        <div v-if="powerExpired > 0">
          您有{{ powerExpired }}份电力需要购买，以免影响提现操作。
        </div>

        <div class="bottom">
          <div class="agree">
            <van-button class="show_btn" color="#124DA5" @click="handleBuy()">去购买</van-button>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Personal, Distribution } from "@/api/index";
export default {
  data() {
    return {
      info: {},
      username: "",
      walletData: {},
      value: 5,
      form: { coins: {} },
      //刷新
      loading: false,
      finished: false,
      data: [],
      page: {
        current_page: 1,
        pagesize: 10,
      },
      judge: false,
      judgemarket: false,
      judgeagent: false,
      aleo: 0,
      io: 0,
      powerShow: false,
      willExpire: 0,
      powerExpired: 0,
      powerType: 1,
      expireIds: '',
    };
  },
  created() {
    this.Info();
    this.walletInfo();
    // aleoWallet && ioWallet
    Personal.aleoWallet().then((res) => {
      this.aleo = res.data.data.balance;
    })
    // Personal.ioWallet().then((res) => {
    //   this.io = res.data.data.balance;
    // })
  },
  methods: {
    goAssets() {
      this.$router.push("/Assets/assets");
    },
    goIoAssets() {
      this.$router.push("/Assets/ioassets");
    },
    //查看团队
    goTeam() {
      this.$router.push("/Distribution/ppsteam");
    },
    goPPS() {
      this.$router.push("/Server/list");
    },
    //跳转购买
    handleBuy() {
      let total = this.powerExpired + this.willExpire;
      this.$router.push({
        path: "/Buy/power",
        query: {
          total: total,
          type: this.powerType,
          ids: this.expireIds,
        }
      })
    },
    //个人信息
    async Info() {
      let { status, data } = await Personal.info();
      if (status) {
        if (data.code == 0) {
          this.info = data.data;
          if (this.info.truename != "") {
            this.username = this.info.truename;
          } else {
            this.username = this.info.passport;
          }
        }
        if (
          typeof data.data.truename == "undefined" ||
          data.data.truename == ""
        ) {
          this.username = data.data.passport;
        } else {
          this.username = data.data.truename;
        }
        // alert power
        let { ppsTotal, power, willExpire, expireIds, notActived } = data.data;
        if (ppsTotal > (power + notActived)) {
          let buyTotal = ppsTotal - power - notActived;
          this.powerExpired = buyTotal;
          this.powerType = 1;
          this.powerShow = true;
        } else if (willExpire > 0) {
          this.willExpire = willExpire;
          this.powerType = 2;
          this.expireIds = expireIds;
          this.powerShow = true;
        }
        // alert power
        this.$store.commit("setBase", {
          username: this.username.username,
          passport: data.data.passport,
          userId: data.data.userId,
          userName: this.username,
          auditStatus: data.data.auditStatus,
          phone: data.data.phone,
        });
      }
    },
    //钱包信息
    async walletInfo() {
      let { status, data } = await Distribution.wallet();
      if (status) {
        if (data.code == 0) {
          this.walletData = data.data;
        }
      }
    },
  },
};
</script>

<style lang="less">
.pact {
  background-color: rgba(255, 255, 255, 0.9);
  overflow-y: auto;
  width: 76vw;
  padding: 5vw;
  font-size: 4vw;
  text-align: justify;
  line-height: 8vw;

  .van-dialog__header {
    font-weight: bold;
  }

  .bottom {
    .swit {
      display: flex;
      padding-top: 4vw;
      justify-content: space-between;

      .font {
        font-size: 3.5vw;
        width: 80%;
        line-height: 5vw;
      }
    }

    .agree {
      padding-top: 3vw;
      display: flex;
      justify-content: space-between;

      .show_btn {
        border-radius: 3vw;
        width: 100%;

      }

      .van-button {}
    }
  }
}

.mobile {
  .van-dialog {
    .van-dialog__confirm {
      background-color: #409eff;
    }
  }
}

.index-bg {
  background: url("../../assets/img/ic _beijing2.png") no-repeat center center;
  background-size: cover;
  height: 100vw;
  width: 100vw;

  .top {
    display: flex;
    justify-content: space-between;
    margin: 0vw 5vw;
    padding-top: 15vw;

    .left {
      .text {
        color: #fff;
        font-size: 4vw;
        padding-top: 3vw;
      }
    }

    .right {
      display: flex;

      .message {
        margin-right: 2vw;
      }
    }
  }

  .terms {
    display: flex;
    margin: 10vw 5vw 0;
    width: 90vw;
    height: 25vw;

    a {
      width: calc(40% - 4vw);
      height: 25vw;
      margin-right: 4vw;
      background-color: #fff;
      border-radius: 4vw;
      box-sizing: border-box;
    }

    a:last-child {
      margin-right: 0vw;
    }

    .item {
      width: 100%;
      height: 25vw;
      margin-right: 4vw;
      background-color: #fff;
      border-radius: 4vw;

      .name {
        padding-top: 6vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        font-size: 3.5vw;
      }

      .filecoin_en {
        padding-top: 3vw;
        padding-left: 2vw;
        color: #fff;
        font-size: 3vw;
      }

      .number {
        padding-top: 4vw;
        padding-left: 2vw;
        color: #fff;
        font-size: 3vw;
      }
    }

    .item1 {
      background-color: #fff;

      .name {
        color: #124da5;
        font-size: 3.5vw;
        margin-left: 1vw;
      }

      .filecoin_en {
        color: #124da5;
        font-size: 3vw;
        padding-left: 3vw;
      }

      .number {
        color: #124da5;
        font-size: 3vw;
        padding-left: 3vw;
      }
    }

    .item2 {
      background-color: #26a6f4;

      .name {
        display: block;
        color: #fff;
        font-size: 3.5vw;
        margin-left: 3vw;
      }

      .filecoin_en {
        color: #fff;
        font-size: 3vw;
        padding-left: 3vw;
      }

      .number {
        color: #fff;
        font-size: 3vw;
        padding-left: 3vw;
      }
    }

    .item3 {
      background-color: #26a6f4;

      .name {
        display: block;
        color: #fff;
        font-size: 3.5vw;
        margin-left: 3vw;
      }

      .filecoin_en {
        color: #fff;
        font-size: 3vw;
        padding-left: 3vw;
      }

      .number {
        color: #fff;
        font-size: 3vw;
        padding-left: 3vw;
      }
    }
  }

  //特殊用户
  .terms_special {
    margin: 5vw;

    .top_special {
      display: flex;
      justify-content: space-between;

      a {
        width: 42.5vw;
        height: 20vw;
        margin-right: 5vw;
        background-color: #fff;
        border-radius: 4vw;
        box-sizing: border-box;
      }

      a:last-child {
        margin-right: 0vw;
      }

      .item {
        width: 100%;
        height: 20vw;
        margin-right: 4vw;
        background-color: #fff;
        border-radius: 4vw;
        color: #124da5;

        .name {
          padding-top: 2vw;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 5vw;
          font-size: 3vw;
        }

        .filecoin_en {
          padding-top: 2vw;
          margin-left: 5vw;
          font-size: 3vw;
        }

        .number {
          padding-top: 2vw;
          margin-left: 5vw;
          font-size: 3vw;
        }
      }

      .item1 {
        background-color: #fff;
        color: #124da5;
      }

      .item2 {
        background-color: #26a6f4;
        color: #fff;
      }
    }

    .bottom_special {
      display: flex;
      justify-content: space-between;
      margin-top: 5vw;

      a {
        width: 42.5vw;
        height: 20vw;
        margin-right: 5vw;
        background-color: #fff;
        border-radius: 4vw;
        box-sizing: border-box;
      }

      a:last-child {
        margin-right: 0vw;
      }

      .item {
        width: 100%;
        height: 20vw;
        margin-right: 4vw;
        background-color: #fff;
        border-radius: 4vw;
        color: #124da5;

        .name {
          padding-top: 2vw;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 5vw;
          font-size: 3vw;
        }

        .filecoin_en {
          padding-top: 2vw;
          margin-left: 5vw;
          font-size: 3vw;
        }

        .number {
          padding-top: 2vw;
          margin-left: 5vw;
          font-size: 3vw;
        }
      }

      .item3 {
        background-color: #26a6f4;
        color: #fff;
      }

      .item4 {
        background-color: #26a6f4;
        color: #fff;
      }
    }
  }
}

.list {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  border-top-right-radius: 10vw;
  border-top-left-radius: 10vw;
  position: absolute;
  top: 70vw;
  left: 50%;
  transform: translateX(-50%);

  .title {
    font-size: 4vw;
    color: #124da5;
    padding-top: 6vw;
    padding-left: 5vw;
  }

  .title_special {
    padding-top: 3vw;
  }

  .operating-box {
    height: 11vw;
    margin: 4vw 5vw;
    padding: 4vw 4vw;
    border-radius: 5vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .asset-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 4vw;
      margin-bottom: 4vw;
      border-bottom: 0.1vw solid #eee;

      .left {
        display: flex;
        align-items: center;

        .my-icon {
          margin-right: 2vw;

          img {
            display: block;
          }
        }

        .text {
          .text1 {
            font-size: 4vw;
            font-weight: bold;
            color: #333333;
            padding-bottom: 0.5vw;
          }

          .text2 {
            padding-top: 0.5vw;
            font-size: 3vw;
            color: #999999;
          }
        }
      }

      .price {
        display: flex;
        align-items: center;
        font-size: 4vw;
        color: #124da5;

        span {
          vertical-align: middle;
        }

        .van-icon {
          vertical-align: middle;
        }
      }
    }

    .asset-item:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  .account-box {
    margin: 4vw 0vw;
    padding: 2vw 4vw 20vw 4vw;

    .account-item {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3vw;
      height: 18vw;
      display: flex;
      align-items: center;
      padding: 0 5vw;
      margin-top: 2vw;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .my-icon {
          margin-right: 1vw;
          margin-top: 1vw;
        }

        .text {
          font-size: 4vw;
          font-weight: bold;
          color: #333333;
        }
      }

      .right {
        font-weight: bold;
        color: #37d39b;
      }
    }
  }

  .server-box {
    margin: 4vw 4vw;
    padding: 2vw 4vw 15vw;

    .server-item {
      height: 30vw;
      display: flex;
      align-items: center;
      margin-top: 0vw;
      border-bottom: 0.2vw solid #eeeeee;

      .right {
        margin-left: 4vw;

        .product_name {
          font-size: 4vw;
          font-weight: bold;
          color: #333333;
          margin-bottom: 1.5vw;
        }

        .T_number {
          font-size: 3.5vw;
          color: #999999;
          margin-bottom: 1.5vw;
        }

        .score {
          margin-bottom: 1vw;
        }

        .operation {
          display: flex;
          width: 54vw;
          align-items: center;
          justify-content: space-between;
          font-size: 3.5vw;

          .tag {
            padding: 1vw;
            background-color: #fdeee9;
            color: #e8541e;
          }

          .buy_product {
            color: #ff1900;

            span {
              vertical-align: middle;
            }

            .van-icon {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>
