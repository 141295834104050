import axios from "axios";
import Vue from "vue";
import {
	setCookie,
	getCookie
} from '@/assets/js/cookie.js'
//设置baseURL
// axios.defaults.baseURL = "http://127.0.0.1:555/";
axios.defaults.baseURL = "https://api.aleopool.top/";

Vue.prototype.$base_url = axios.defaults.baseURL

// 添加请求拦截器
axios.interceptors.request.use(function(config) {
	let token = localStorage.getItem('token');
	config.headers.authorization = 'Bearer ' + token;
	if (token == '' || token == null) {
		// token = getCookie('token')
		localStorage.token = token

	}
	if (config.method === 'get' || config.method === 'delete') {
		// config.params.token = token;
	} else {
		// config.data.token = token;
	}
	// 在发送请求之前做些什么
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
	if (response.status == 200 || response.status == 0) {
		//请求异常报错处理
		if (response.data.code == 401 || response.data.code == 420) {
			localStorage.token = null
			//清空cookie
			var cookies = document.cookie.split(";");
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				var eqPos = cookie.indexOf("=");
				var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie =
					name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
			}
			if (cookies.length > 0) {
				for (var i = 0; i < cookies.length; i++) {
					var cookie = cookies[i];
					var eqPos = cookie.indexOf("=");
					var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
					var domain = location.host.substr(location.host.indexOf("."));
					document.cookie =
						name +
						"=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
						domain;
				}
			}
			setTimeout(() => {

				window.location.href = "/login/";
			}, 800);

		} else {
			if (response.data.code == 500) {
				setTimeout(() => {
					window.location.href = "/login/";
				}, 800);
				return
			}
			// 对响应数据做点什么
			return response;
		}
	}

}, function(error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});
